<template>
  <div class="container-footer">
    <div class="container-footer__bg"></div>
    <div
      class="d-flex text-white justify-content-between pb-3 align-items-center align-items-xl-start flex-column flex-xl-row col-12 col-sm-10"
    >
      <div class="d-flex align-items-center flex-column flex-xl-row">
        <router-link to="/" class="d-none d-xl-block"
          ><img alt="logo" v-lazy="logo" width="158px" height="133px"
        /></router-link>
        <router-link to="/" class="d-xl-none d-block"
          ><img alt="logo" v-lazy="logo_small" width="132.001px" height="26px"
        /></router-link>
        <div
          class="d-flex flex-column justify-content-start align-items-center align-items-xl-start mt-4 mt-xl-0 ml-xl-4 ml-xl-5"
        >
          <div class="m-0 h5 font-weight-bold">{{ award.title }}</div>
          <span class="mt-2">{{ award.description }}</span>
          <div class="d-flex align-items-center mt-3">
            <img
              alt="iso9001"
              v-lazy="iso9001"
              width="92px"
              height="auto"
              class="iso9001-img"
            />
            <img
              alt="iso27001"
              v-lazy="iso27001"
              width="117px"
              height="auto"
              class="ml-4 iso27001-img"
            />
            <img
              alt="awardsaokhue"
              v-lazy="awardsaokhue"
              width="85px"
              height="auto"
              class="ml-4 awardsaokhue-img"
            />
          </div>
        </div>
      </div>
      <div
        class="my-4 d-block d-xl-none mx-auto"
        style="background-color: #c5c5c5; height: 1px; width: 80%"
      ></div>
      <div
        class="d-flex flex-column justify-content-start text-center text-xl-left"
      >
        <router-link
          v-for="(item, index) in listItems"
          v-bind:key="index"
          @click="clickMenu(item.link, index)"
          :to="item.link"
          class="text-white text-nowrap pb-2 px-1 font-weight-bold btn-hover"
          >{{ item.name }}
        </router-link>
        <a
          href="https://blog.tgl-cloud.com/"
          target="_blank"
          class="text-white text-nowrap pb-2 px-1 font-weight-bold btn-hover"
          >{{ header.blog }}
        </a>
        <a
          :href="`https://news.tgl-cloud.com/${$i18n.locale === 'jp' ? 'ja' : $i18n.locale === 'vn' ? 'vi' : $i18n.locale}`"
          target="_blank"
          class="text-white text-nowrap pb-2 px-1 font-weight-bold btn-hover"
          >{{ header.news }}
        </a>
      </div>
      <div
        class="my-4 d-block d-xl-none mx-auto"
        style="background-color: #c5c5c5; height: 1px; width: 80%"
      ></div>
      <div
        class="d-flex flex-column justify-content-start text-center text-xl-left"
      >
        <div class="font-weight-bold">{{ contents[1].title }}</div>
        <a
          :href="contents[1].ref2"
          target="_blank"
          class="text-white py-1 mt-1 btn-hover"
          @click="clickSocial('phone_click', 'Phone Button')"
        >
          <span>{{ contents[1].content2 }}</span>
        </a>
        <a
          :href="contents[1].ref1"
          target="_blank"
          class="text-white py-1 mt-1 btn-hover"
          @click="clickSocial('mail_click', 'Mail Button')"
        >
          <span>{{ contents[1].content1 }}</span>
        </a>
        <a
          :href="contents[2].ref1"
          target="_blank"
          class="text-white py-1 mt-1 btn-hover"
          @click="clickSocial('facebook_click', 'Facebook Button')"
        >
          <span>Facebook</span>
        </a>
        <a
          :href="contents[2].ref3"
          target="_blank"
          class="text-white py-1 mt-1 btn-hover"
          @click="clickSocial('youtube_click', 'Youtube Button')"
        >
          <span>Youtube</span>
        </a>
      </div>
      <div
        class="my-4 d-block d-xl-none mx-auto"
        style="background-color: #c5c5c5; height: 1px; width: 80%"
      ></div>
      <div
        class="d-flex flex-column justify-content-start text-center text-xl-left m-auto m-xl-0"
        style="width: 190px !important"
      >
        <div class="font-weight-bold">{{ contents[0].title }}</div>
        <a
          :href="contents[0].ref1"
          target="_blank"
          class="text-white py-1 mt-1 btn-hover"
          style="white-space: pre-line"
          @click="clickSocial('location_click', 'Location Button')"
        >
          {{ contents[0].content11 }}
          <span class="d-block mt-2">{{ contents[0].content12 }}</span>
        </a>
      </div>
    </div>
    <div
      class="py-4 text-white border-top w-100 d-flex align-items-center justify-content-center px-4 px-xl-0 text-center"
      style="border-color: #838383 !important"
    >
      Copyright © 2019-2024 TGL Solutions. All rights reserved.
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'Footer',
  data() {
    return {
      logo: '/images/footer/logo.webp',
      logo_small: '/images/footer/logo_small.webp',
      iso9001: '/images/footer/iso9001.webp',
      iso27001: '/images/footer/iso27001.webp',
      awardsaokhue: '/images/footer/awardsaokhue.webp',
      contents: this.$t('footer').contents,
      award: this.$t('footer').award,
      header: this.$t('header'),
      listItems: [
        {
          name: this.$t('header').home,
          router: 'Home',
          link: '/',
        },
        {
          name: this.$t('header').about_us,
          router: 'AboutUs',
          link: '/about-us',
        },
        {
          name: this.$t('header').why_us,
          router: 'People',
          link: '/why-us',
        },
        {
          name: this.$t('header').projects,
          router: 'Projects',
          link: '/projects',
        },
        {
          name: this.$t('header').recruiment,
          router: 'Recruitment',
          link: '/recruitment',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang,
    }),
  },
  methods: {
    updateListItem() {
      this.listItems = [
        {
          name: this.$t('header').home,
          router: 'Home',
          link: '/',
        },
        {
          name: this.$t('header').about_us,
          router: 'AboutUs',
          link: '/about-us',
        },
        {
          name: this.$t('header').why_us,
          router: 'People',
          link: '/why-us',
        },
        {
          name: this.$t('header').projects,
          router: 'Projects',
          link: '/projects',
        },
        {
          name: this.$t('header').recruiment,
          router: 'Recruitment',
          link: '/recruitment',
        },
      ];
    },
    clickSocial(event, eventLabel) {
      // Send custom event GA4
      this.$gtag.event(event, {
        'event_category': 'Footer',
        'event_label': eventLabel
      })
      this.$router.push('/contact')
    }
  },
  watch: {
    lang(val) {
      this.$i18n.locale = val;
      this.contents = this.$t('footer').contents;
      this.header = this.$t('header');
      this.award = this.$t('footer').award;
      this.updateListItem();
    },
  },
};
</script>

<style lang="scss" scoped>
.container-footer {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 50px;
  z-index: 8;
  background: linear-gradient(180deg, #002382 0%, #0836b1 100%);
  overflow: hidden;
  .container-footer__bg {
    position: absolute;
    mix-blend-mode: color-dodge;
    width: 90%;
    height: 70%;
    bottom: 0;
    right: 0;
    background-image: url('/images/footer/blend.svg');
    background-repeat: no-repeat;
    background-position: right bottom;
    z-index: -2;
  }
  .btn-hover:hover {
    opacity: 0.5;
    transition: 0.3s;
  }

  @media (max-width: 768px) {
    .iso9001-img {
      width: 38px;
      height: auto;
    }
    .iso27001-img {
      width: 58px;
      height: auto;
    }
    .awardsaokhue-img {
      width: 35px;
      height: auto;
    }
  }
  @media (max-width: 1140px) {
    .container-footer__bg {
      background-image: url('/images/footer/blend-mobile.svg');
      width: 100%;
    }
  }
}
</style>
